import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    nav: {
        display: 'flex',
        listStyle: 'none',
        float: 'right',

        '& li': {
            marginRight: 10,

            '& a': {
                textDecoration: 'none',
            },

            '& a.active': {
                textDecoration: 'underline',
            }
        }
    },
}));

const Navigation = () => {
    const classes = useStyles();

    return (
        <ul className={classes.nav}>
            <li><NavLink to="/" exact activeClassName="active">Home</NavLink></li>
            <li><NavLink to="/dashboard" exact activeClassName="active">Dashboard</NavLink></li>
        </ul>
    );
};

export default Navigation;
