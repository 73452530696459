import { propList } from './data';

const filterByInnerJoin = (filterList = [], itemList = []) => {
    const filterSet = new Set(filterList || []);

    for (let i = 0; i < itemList.length; i++) {
        const val = itemList[i];
        if (filterSet.has(val)) {
            return true;
        }
    }

    return false;
};


export const getPropList = (filters) => {
    return new Promise((resolve) => {
        let newPropList = propList;

        if (filters.categories && filters.categories.length) newPropList = newPropList.filter((item) => filterByInnerJoin(filters.categories, item.categories));
        if (filters.colors && filters.colors.length) newPropList = newPropList.filter((item) => filterByInnerJoin(filters.colors, item.colors));
        if (filters.tags && filters.tags.length) newPropList = newPropList.filter((item) => filterByInnerJoin(filters.tags, item.tags));
        if (filters.vendors && filters.vendors.length) newPropList = newPropList.filter((item) => filterByInnerJoin(filters.vendors, item.vendors));

        resolve(newPropList);
    });
};