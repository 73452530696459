import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'center',
    },
}));

const NoProducts = () => {
    const classes = useStyles();

    return (
        <p className={classes.title}>
            No products available for this search criteria.
        </p>
    );
};

export default NoProducts;
