import { useEffect, useState } from 'react';

import { VIEW } from './constants';
import { getPropList } from './helpers';
import Header from './Header';
import ProductGallery from './ProductGallery';
import ProductModal from './ProductModal';
import ProductTable from './ProductTable';
import NoProducts from './NoProducts';
import Loader from './Loader';
import Navigation from '../../components/Navigation';

const Catagolue = () => {
  const [ currentProduct, setCurrentProduct ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ filters, setFilters ] = useState({});
  const [ open, setOpen ] = useState(false);
  const [ propList, setPropList ] = useState([]); 
  const [ viewType, setViewType ] = useState(VIEW.GALLERY);

  useEffect(() => {
    setIsLoading(true);
    getPropList(filters)
      .then((resp) => {
        setPropList(resp);
        setIsLoading(false);
      });
  }, [ filters ]);

  const onClearFilter = () => {
    setFilters({});
  };

  const onFilter = (key, value) => {
    let newKeyFilter;
    const currentKeyValue = filters[key] || [];

    if (currentKeyValue.includes(value)) {
      newKeyFilter = currentKeyValue.filter((item) => item !== value);
    } else {
      newKeyFilter = [ ...currentKeyValue, value ];
    }

    const newFilter = {
      ...filters,
      [key]: newKeyFilter,
    };

    setFilters(newFilter);
  };

  const onOpen = (product) => {
    setOpen(true);
    setCurrentProduct(product);
  };

  const onClose = () => {
    setOpen(false);
    setCurrentProduct(null);
  };

  const getContent = () => {
    if (isLoading) return <Loader />;

    if (propList.length === 0) {
      return (
        <NoProducts
          onClearFilter={onClearFilter}
        />
      );
    }
    
    if (viewType === VIEW.LIST) {
      return (
        <ProductTable
          propList={propList}
          onOpen={onOpen}
        />
      );
    }

    if (viewType === VIEW.GALLERY) {
      return (
        <ProductGallery
          propList={propList}
          onOpen={onOpen}
        />
      );
    }
  };

  return (
    <>
      <Navigation />
      <Header
        count={propList.length}
        filters={filters}
        onFilter={onFilter}
        setViewType={setViewType}
        viewType={viewType}
      />

      {currentProduct && (
        <ProductModal
          open={open}
          onClose={onClose}
          currentProduct={currentProduct}
        />
      )}

      {getContent()}
    </>
  );
};

export default Catagolue;
