import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';

import Row from './Row';

const TBody = ({
    onOpen,
    propList,
}) => (
    <TableBody>
        {propList.map((propItem, index) => (
            <Row
                key={index}
                onOpen={onOpen}
                {...propItem}
            />
        ))}
    </TableBody>
);

TBody.propTypes = {
    onOpen: PropTypes.func,
    propList: PropTypes.array,
};

export default TBody;
