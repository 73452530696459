import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const THead = () => (
    <TableHead>
        <TableRow>
            <TableCell>Images</TableCell>
            <TableCell>Categories</TableCell>
            <TableCell>Colors</TableCell>
            <TableCell>Tags</TableCell>
            <TableCell>Vendor</TableCell>
            <TableCell>Notes</TableCell>
        </TableRow>
    </TableHead>
);

export default THead;
