import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import FilterItem from './FilterItem';

const FilterSection = ({
    filters,
    filterKey,
    filterList,
    onFilter,
    openSection,
    setOpenSection,
}) => (
    <>
        <ListItem
            button
            onClick={() => setOpenSection(!openSection)}
        >
            <ListItemText primary={filterKey} />
        </ListItem>

        <Collapse
            in={openSection}
            timeout="auto"
            unmountOnExit
        >
            <List
                component="div"
                disablePadding
            >
                {filterList.map((filter, index) => (
                    <FilterItem
                        key={index}
                        filter={filter}
                        filterKey={filterKey}
                        filters={filters}
                        onFilter={onFilter}
                    />
                ))}
            </List>
        </Collapse>

        <Divider />
    </>
);

FilterSection.propTypes = {
    filters: PropTypes.array,
    filterKey: PropTypes.string,
    filterList: PropTypes.array,
    onFilter: PropTypes.func,
    openSection: PropTypes.bool,
    setOpenSection: PropTypes.func,
};

FilterSection.defaultProps = {
    openSection: false,
    filters: [],
};

export default FilterSection;
