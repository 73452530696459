export const COLORS =  {
    BLUE: 'blue',
    GREEN: 'green',
    PINK: 'pink',
};

export const COLORS_LIST = [
    COLORS.BLUE,
    COLORS.GREEN,
    COLORS.PINK,
];

export const CATGORIES =  {
    BACK_DROP: 'back-drop',
    WRAP: 'wrap',
};

export const CATGORIES_LIST = [
    CATGORIES.BACK_DROP,
    CATGORIES.WRAP,
];

export const TAGS =  {
    CAKE_SMASH: 'cake-smash',
    NEWBORN: 'newborn',
};

export const TAGS_LIST = [
    TAGS.CAKE_SMASH,
    TAGS.NEWBORN,
];

export const VENDORS = {
    BOUNCING_BABY_BOY: 'bouncing-baby-boy',
    JACK_AND_WIN: 'jack-and-win',
};

export const VENDORS_LIST = [
    VENDORS.BOUNCING_BABY_BOY,
    VENDORS.JACK_AND_WIN,
];