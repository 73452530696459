import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';

import TBody from './TBody';
import THead from './THead';
  
const ProductTable = ({
    onOpen,
    propList,
}) => (
    <Table>
        <THead />
        <TBody
        propList={propList}
        onOpen={onOpen}
        />
    </Table>
);

ProductTable.propTypes = {
    onOpen: PropTypes.func,
    propList: PropTypes.array,
};

export default ProductTable;
