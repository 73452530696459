import PropTypes from 'prop-types';

const Td = ({
    dataList,
}) => {
    if (!dataList || dataList.length === 0) return null;

    return dataList.map((item, index) => (
        <span
            key={index}
        >{item}</span>
    ));
};

Td.propTypes = {
    dataList: PropTypes.array,
};

Td.defaultProps = {
    dataList: [],
};
    
export default Td;
