import PropTypes from 'prop-types';

const Attribute = ({
    attribute,
    label,
}) => {
    if (!attribute) return null;

    const str = attribute.join(',');

    return (
        <>
            <p><strong>{label}</strong></p>
            <p>{str}</p>
        </>
    );
};

Attribute.propTypes = {
    attribute: PropTypes.array,
    label: PropTypes.string,
};

export default Attribute;