import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'center',
    },
}));

const Loader = () => {
    const classes = useStyles();

    return (
        <p className={classes.title}>
            Loading...
        </p>
    );
};

export default Loader;
