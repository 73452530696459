import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';

const useStyles = makeStyles((theme) => ({
    activeIcon: {
        color: 'green',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

const FilterItem = ({
    filter,
    filterKey,
    filters,
    onFilter,
}) => {
    const classes = useStyles();
    const isSelected = (filters || []).includes(filter);

    return (
        <ListItem
            button
            className={classes.nested}
            onClick={() => onFilter(filterKey, filter)}
            selected={isSelected}
        >
            <ListItemText primary={filter} />
            <ListItemIcon>
                {isSelected ? (
                    <CheckCircle
                        className={classes.activeIcon}
                    />
                ) : (
                    <RadioButtonUnchecked />
                )}
            </ListItemIcon>
        </ListItem>
    );
};

FilterItem.propTypes = {
    filter: PropTypes.object,
    filterKey: PropTypes.string,
    filters: PropTypes.array,
    onFilter: PropTypes.func,
};

FilterItem.defaultProps = {
};

export default FilterItem;
