const API_URL = 'http://photo-props.travishoki.com/api';

const b64toBlob = (dataURI) => {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], {type: mimeString});
    
    return blob;
}

export const uploadProduct = (pictures, pictureDataURLs) => {
    const data = new FormData();
    data.append(
        'image',
        b64toBlob(pictureDataURLs[0]),
        pictures[0].name,
    );

    return fetch(`${API_URL}/product-upload.php`, {
        method: 'POST',
        body: data,
    });
};