import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImageUploader from 'react-images-upload';

import InputColor from './InputColor';
import { uploadProduct } from './helpers';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'block',
  },
}));

const ProductForm = () => {
  const classes = useStyles();

  const [color, setColor] = useState('');
  const [pictures, setPictures] = useState([]);
  const [pictureDataURLs, setPictureDataURLs] = useState([]);

  const onChangeColor = (event) => {
    setColor(event.target.value);
  };

  const onDrop = (picture, pictureDataURL) => {
    setPictures(pictures.concat(picture));
    setPictureDataURLs(pictureDataURLs.concat(pictureDataURL));
  };

  const onSubmit = (event) => {
    event.preventDefault();

    uploadProduct(pictures, pictureDataURLs)
      .then((resp) => resp.json())
      .then((resp) => {
        console.log('%c<< ---- %s -----', 'font-size: 12px;', 'uploadProduct - then');
        console.log('<< resp:');console.dir(resp);
      })
      .catch((resp) => {
        console.log('%c<< ---- %s -----', 'font-size: 12px;', 'uploadProduct - catch');
        console.log('<< resp:');console.dir(resp);
      });
  };

  return (
    <form
      onSubmit={onSubmit}
      className={classes.form}
    >
      <InputColor
        color={color}
        handleChange={onChangeColor}
      />

      <ImageUploader
        withIcon={true}
        buttonText='Choose images'
        onChange={onDrop}
        imgExtension={['.jpg', '.gif', '.png', '.gif']}
        maxFileSize={5242880}
      />

      <input
        type="submit"
        name="submit"
        value="Submit"
      />
    </form>
  );
};

export default ProductForm;
