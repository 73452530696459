import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
    paper: {
        cursor: 'pointer',
        overflow: 'Hidden',
    },
    productImage: {
        width: '100%',
    },
    root: {
      flexGrow: 1,
      padding: 20,
    },
}));
  
const ProductGallery = ({
    onOpen,
    propList,
}) => {
    const classes = useStyles();

    return (
        <Grid
            container
            className={classes.root}
            spacing={2}
        >
            {propList.map((propItem, index) => (
                <Grid
                    key={index}
                    item
                    xs={6}
                    sm={4}
                    md={2}
                >
                    <Paper
                        className={classes.paper}
                        onClick={() => onOpen(propItem)}
                    >
                        <img
                            alt="prop"
                            src={propItem.images[0]}
                            className={classes.productImage}
                        />
                    </Paper>
                </Grid>
            ))}
        </Grid>
    );
};

ProductGallery.propTypes = {
    onOpen: PropTypes.func,
    propList: PropTypes.array,
};

export default ProductGallery;
