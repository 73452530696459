import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const IMAGE_SIZE = 50;

const useStyles = makeStyles((theme) => ({
    productImage: {
        cursor: 'pointer',
    },
}));

const Td = ({
    dataList,
    onClick,
}) => {
    const classes = useStyles();

    if (!dataList || dataList.length === 0) return null;

    return dataList.map((item, index) => (
        <img
            key={index}
            alt="prop"
            className={classes.productImage}
            height={IMAGE_SIZE}
            onClick={onClick}
            src={item}
            width={IMAGE_SIZE}
        />
    ));
};

Td.propTypes = {
    dataList: PropTypes.array,
    onClick: PropTypes.func,
};

Td.defaultProps = {
    dataList: [],
};
    
export default Td;
