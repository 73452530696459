import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import { COLORS_LIST } from '../../../../constants';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const ProductForm = ({
    color,
    handleChange,
}) => {
    const classes = useStyles();

    return (
        <FormControl
            variant="filled"
            className={classes.formControl}
        >
            <InputLabel
                htmlFor="colors-field"
            >
                Colors
            </InputLabel>
            <Select
                native
                value={color}
                onChange={handleChange}
                inputProps={{
                name: 'colors',
                id: 'colors-field',
                }}
            >
                <option
                    aria-label="None"
                    value=""
                />
                {COLORS_LIST.map((color, index) => (
                    <option
                        key={index}    
                        value={color}
                    >
                        {color}
                    </option>
                ))}
            </Select>
        </FormControl>
    );
};

ProductForm.propTypes = {
    color: PropTypes.string,
    handleChange: PropTypes.func,
};

export default ProductForm;
