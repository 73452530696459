import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Catalogue from './route/catalogue';
import Dashboard from './route/dashboard';
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" exact component={Catalogue} />
          <Route path="/dashboard" component={Dashboard} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
