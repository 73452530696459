import ProductForm from './ProductForm';
import Navigation from '../../components/Navigation';

const Dashboard = () => (
  <>
      <Navigation />
      <ProductForm />
  </>
);

export default Dashboard;
