import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import DataCell from './DataCell';
import ImageCell from './ImageCell';

const Row = ({
  categories,
  colors,
  images,
  notes,
  onOpen,
  tags,
  vendors,
}) => (
  <TableRow>
    <TableCell>
      <ImageCell
        dataList={images}
        onClick={() => onOpen({
          categories,
          colors,
          images,
          notes,
          onOpen,
          tags,
          vendors,
         })}
     />
    </TableCell>
    <TableCell><DataCell dataList={categories} /></TableCell>
    <TableCell><DataCell dataList={colors} /></TableCell>
    <TableCell><DataCell dataList={tags} /></TableCell>
    <TableCell><DataCell dataList={vendors} /></TableCell>
    <TableCell><DataCell dataList={notes} /></TableCell>
  </TableRow>
);

Row.propTypes = {
  categories: PropTypes.array,
  colors: PropTypes.array,
  notes: PropTypes.array,
  onOpen: PropTypes.func,
  images: PropTypes.array,
  tags: PropTypes.array,
  vendors: PropTypes.array,
};

Row.defaultProps = {
  categories: [],
  colors: [],
  images: [],
  notes: [],
  tags: [],
  vendors: [],
};

export default Row;
