import { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import FilterListIcon from '@material-ui/icons/FilterList';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import ButtonGroup from '@material-ui/core/ButtonGroup';

import { VIEW } from '../constants';
import Filters from '../Filters';

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    marginRight: 10,
  },
  header: {
      padding: 10,
      display: 'flex',
  },
}));

const Header = ({
  count,
  filters,
  onFilter,
  setViewType,
  viewType,
}) => {
  const classes = useStyles();

  const [ isFilterMenuOpen, setIsFilterMenuOpen ] = useState(false);

  const handleAlignment = (event, newAlignment) => setViewType(newAlignment);
  
  return (
    <header
      className={classes.header}
    >
        <ButtonGroup
          className={classes.buttonGroup}
        >
          <Button
              onClick={() => setIsFilterMenuOpen(true)}
          >
            <FilterListIcon />
          </Button>
        </ButtonGroup>

        <ToggleButtonGroup
          value={viewType}
          exclusive
          onChange={handleAlignment}
          aria-label="product view"
        >
          <ToggleButton
            value={VIEW.GALLERY}
            aria-label="gallery view"
          >
            <ViewModuleIcon />
          </ToggleButton>
          <ToggleButton
            value={VIEW.LIST}
            aria-label="list view"
          >
            <ViewListIcon />
          </ToggleButton>
        </ToggleButtonGroup>

        <Drawer
            anchor='left'
            open={isFilterMenuOpen}
            onClose={() => setIsFilterMenuOpen(false)}
        >
            <Filters
                count={count}
                filters={filters}
                onClose={() => setIsFilterMenuOpen(false)}
                onFilter={onFilter}
            />
        </Drawer>
    </header>
  );
}

Header.propTypes = {
  count: PropTypes.number,
  filters: PropTypes.object,
  onFilter: PropTypes.func,
  setViewType: PropTypes.func,
  viewType: PropTypes.string,
};

Header.defaultProps = {
  count: 0,
};

export default Header;