import {
    CATGORIES,
    COLORS,
    TAGS,
    VENDORS,
} from '../../constants';

export const propList = [
    {
        categories: [
            CATGORIES.WRAP,
        ],
        colors: [
            COLORS.PINK,
        ],
        images: [
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvJrfufPCYmEtgvHsJzCAo5N4EW4ReZgl-sw&usqp=CAU',
        ],
        tags: [
            TAGS.NEWBORN,
        ],
        vendors: [
            VENDORS.JACK_AND_WIN,
        ],
    },
    {
        categories: [
            CATGORIES.WRAP,
        ],
        colors: [
            COLORS.BLUE,
        ],
        images: [
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR47LVxz0hIjXMOPG-xJ23hHntEK4jE3-HZWA&usqp=CAU',
        ],
        tags: [
            TAGS.NEWBORN,
        ],
    }
];