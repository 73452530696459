import { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';

import FilterSection from './FilterSection';
import {
    CATGORIES_LIST,
    COLORS_LIST,
    TAGS_LIST,
    VENDORS_LIST,
} from '../../../constants';

const useStyles = makeStyles((theme) => ({
    closeButton: {
        cursor: 'pointer',
        position: 'absolute',
        right: 2,
        top: 2,
        zIndex: 100,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    root: {
        width: '100%',
        maxWidth: 360,
        minWidth: 300,
        backgroundColor: theme.palette.background.paper,
    },
    subheader: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

const Filters = ({
    count,
    filters,
    onClose,
    onFilter,
}) => {
    const classes = useStyles();
    const [ openCategories, setOpenCategories ] = useState(true);
    const [ openColors, setOpenColors ] = useState(true);
    const [ openTags, setOpenTags ] = useState(true);
    const [ openVendors, setOpenVendors ] = useState(true);
  
    return (
        <>
            <CloseIcon
                className={classes.closeButton}
                onClick={onClose}
            />

            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader
                        component="div"
                        id="nested-list-subheader"
                        className={classes.subheader}
                    >
                        <p>Filters:</p>
                        <p>Results: {count}</p>
                    </ListSubheader>
                }
                className={classes.root}
            >
                <Divider />

                <FilterSection
                    filters={filters.categories}
                    filterKey="categories"
                    filterList={CATGORIES_LIST}
                    onFilter={onFilter}
                    openSection={openCategories}
                    setOpenSection={setOpenCategories}
                />

                <FilterSection
                    filters={filters.colors}
                    filterKey="colors"
                    filterList={COLORS_LIST}
                    onFilter={onFilter}
                    openSection={openColors}
                    setOpenSection={setOpenColors}
                />

                <FilterSection
                    filters={filters.tags}
                    filterKey="tags"
                    filterList={TAGS_LIST}
                    onFilter={onFilter}
                    openSection={openTags}
                    setOpenSection={setOpenTags}
                />

                <FilterSection
                    filters={filters.vendors}
                    filterKey="vendors"
                    filterList={VENDORS_LIST}
                    onFilter={onFilter}
                    openSection={openVendors}
                    setOpenSection={setOpenVendors}
                />
            </List>
        </>
    );
};

Filters.propTypes = {
    count: PropTypes.number,
    filters: PropTypes.object,
    onClose: PropTypes.func,
    onFilter: PropTypes.func,
};

Filters.defaultProps = {
    count: 0,
};

export default Filters;
