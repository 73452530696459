import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import Attribute from './Attribute';

const useStyles = makeStyles((theme) => ({
    closeButton: {
        cursor: 'pointer',
        position: 'absolute',
        right: 5,
        top: 5,
    },
    content: {
        height: '100%',
        overflowY: 'scroll',
        margin: '0 auto',
        maxWidth: 400,
        width: '100%',
    },
    paper: {
        padding: 40,
    },
    productImage: {
        display: 'block',
        margin: '0 auto',
        width: '100%',
    },
}));

const ProductModal = ({
    currentProduct,
    onClose,
    open,
}) => {
    const classes = useStyles();

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div className={classes.paper}>
                <CloseIcon
                    className={classes.closeButton}
                    onClick={onClose}
                    fontSize="large"
                />
                <div className={classes.content}>
                    <img
                        alt="prop"
                        className={classes.productImage}
                        src={currentProduct.images[0]}
                    />
                    <Attribute
                        label="Colors"
                        attribute={currentProduct.colors}
                    />
                    <Attribute
                        label="Categories"
                        attribute={currentProduct.categories}
                    />
                    <Attribute
                        label="Tags"
                        attribute={currentProduct.tags}
                    />
                    <Attribute
                        label="Vendors"
                        attribute={currentProduct.vendors}
                    />
                    <Attribute
                        label="Notes"
                        attribute={currentProduct.notes}
                    />
                </div>
            </div>
        </Dialog>
    );
};

ProductModal.propTypes = {
    currentProduct: PropTypes.object,
    onClose: PropTypes.func,
    open: PropTypes.bool,
};
  
ProductModal.defaultProps = {
    open: false,
};

export default ProductModal;